.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px ;
    background-color: #fff;
    padding: 0 70px;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    
}

h4{
    font-size: 50px;
    margin-right: 80px;
    justify-content: left;

    
}

.nav-menu a{
    padding: 0 1rem;
    font-size: 1.2rem;
    font-weight: 200;
    text-decoration: none;
    color: black;
}


.nav-menu a:hover{
    color: #DA171B;
    transition: 0.5s;
}


header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    display: none;
    opacity: 0;
}

@media only screen and (max-width:1024px) {
    
    .navbar{
        padding: 0 30px;
    }
    
    header .nav-btn{
        display: flex;
        opacity: 1;
    }

    header nav{
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: center;
        justify-content: center;
        gap: 1.5em;
        transition: 1s;
        background-color: #DA171B;
        transform: translateX(100vh);
        color: #fff;
    }

    .nav-menu a , .nav-close-btn{
        color: #fff;
        font-size: 16px;
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
}