.main-container{
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url('../assets/Images/bg.jpg');
    background-size: cover;
    background-position: top;
}


.main {
    padding: 100px 100px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
.main h2 {
    font-size: 1.8em;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
  }

  .main h1{
    font-size: 1.2em;
    font-weight: 300;
    color: #fff;
    margin-bottom: 30px;
  }

  .main-btn{
        text-decoration: none;
        color: #fff;
        background-color: #d82626;
        border-radius: 10px;
        font-size: 1em;
        font-weight: 200;
        letter-spacing: 1;
        display: inline-block;
        padding: 0.9375em 4.1875em;
        transition: 0.7s ease;
        border: none;
  }

  .main-btn:hover{
    border-color: #fff;
    padding: 0.9375em 4.9875em;
    transition: 0.5s ease;   
}
@media only screen and (max-width:1024px) {
    
  .main {
    padding: 50px 50px;
  }
  .main h2{
      font-size: 1em;
      font-weight: 400;
    }
  
    .main h1{
      font-size: 0.9em;
      font-weight: 300;
    }

    .main-btn{
      font-size: 0.9em;
      font-weight: 200;
      letter-spacing: 1;
      display: inline-block;
      padding: 0.9375em 3.1875em;
      transition: 0.7s ease;
      border: none;
}
}